







































































































































import { PropType, defineComponent, computed, ref } from '@vue/composition-api';
import moment from 'moment';
import axios from 'axios';

export default defineComponent({
  name: 'SignOffIntern',
  props: {
    value: {
      required: true,
      type: Object as PropType<Record<string, any>>
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const selectedStudent = computed(() => props.value);
    const showDialog = computed({
      get: () => props.isOpen,
      set: newVal => {
        ctx.emit('toggle');
      }
    });
    const listOfRatings = ref([
      {
        label: 'Oral Communication',
        value: 0
      },
      {
        label: 'Written Communication',
        value: 0
      },
      {
        label: 'Initiative',
        value: 0
      },
      {
        label: 'Interaction with staff',
        value: 0
      },
      {
        label: 'Attitude',
        value: 0
      },
      {
        label: 'Dependability',
        value: 0
      },
      {
        label: 'Ability to learn',
        value: 0
      },
      {
        label: 'Planning Organization',
        value: 0
      },
      {
        label: 'Professionalism',
        value: 0
      },
      {
        label: 'Creativity',
        value: 0
      },
      {
        label: 'Quality of Work',
        value: 0
      },
      {
        label: 'Productivity',
        value: 0
      },
      {
        label: 'Appearance',
        value: 0
      },
      {
        label: 'Adaptability',
        value: 0
      },
      {
        label: 'Overall Performance',
        value: 0
      }
    ]);
    const remarks = ref('');
    const singningOff = ref(false);

    const totalHrs = computed(() => {
      return selectedStudent.value?.timeSheetHrs;
    });

    async function signOffIntern() {
      try {
        singningOff.value = true;
        const API = process.env.VUE_APP_SIGNOFF_SERVICE;
        const data = {
          type: 'signoff',
          student_id: selectedStudent.value._id.toString(),
          ratings: listOfRatings.value,
          remarks: remarks.value
        };
        const res = await axios.post(`${API}/signoff-intern`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          selectedStudent.value.offerDetails = res.data.data?.offerDetails;
          ctx.emit('signed-off');
          singningOff.value = false;
        }
      } catch (error) {
        console.log(error);
        singningOff.value = false;
      }
    }

    return {
      showDialog,
      signOffIntern,
      totalHrs,
      listOfRatings,
      selectedStudent,
      singningOff,
      remarks
    };
  }
});
